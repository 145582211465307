import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Hip Hop";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το Hip Hop είναι ένα ολόκληρο πνευματικό κίνημα, που καταρχάς
          εκφράστηκε από τις μειονότητες του αστικού κέντρου της Νέας Υόρκης το
          1970, ως ένας τρόπος εκτόνωσης.
        </P>
        <P>
          Ομάδες παιδιών άρχισαν να συγκεντρώνονται και να διαγωνίζονται μεταξύ
          τους χορεύοντας, ενώ όσοι από αυτούς δε χόρευαν, τραγουδούσαν
          αυτοσχέδιους στίχους κι έφτιαχναν μουσική με το σώμα τους (human
          jukebox), τη λεγόμενη rap μουσική.
        </P>
        <P>
          Κάτω από την ομπρέλα της Hip Hop χορευτικής κουλτούρας βρίσκονται
          τέσσερα βασικά στυλ: το popping, το locking, το boogaloo και το
          breakdance.
        </P>
      </Section.Main>
    </Layout>
  );
}
